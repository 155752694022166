<template>
<div class="flex flex-col items-center my-4 p-2">
    <div v-show="asFullPage" class=" flex items-center justify-center">
        <Iconsign/>
        <span class="text-2xl font-semibold">{{ $t("sign.title") }}</span>
    </div>

    <Iconrbill v-show="!asFullPage"/>
    <IconBigbill v-show="asFullPage"/>
    <span v-show="!asFullPage" class="text-xl font-bold text-center mb-2" style="color:#222F5A"
    v-html="$t('rechargeSuccess.receiveBill')">
    </span>

    <label v-show="asFullPage" class="mt-3 text-sm w-11/12 text-left">{{ $t("sign.registerLabel") }}</label>
    <div class="mt-1 bg-gray-300 inline-flex w-11/12" style="border-radius: 1rem;">
        <button @click="billReceiveMethod" type="button"
            :class="!telephone?'bg-white transition-all duration-500 transform ':''"
            class="focus:outline-none m-2 text-gray-800 font-bold py-2 px-4 w-1/2 flex justify-center"
            style="border-radius: 0.75rem;">
            <Iconsmail />
            <span class="ml-1 text-xs">Email</span>
        </button>
        <button @click="billReceiveMethod" type="button"
            :class="telephone?'bg-white transition-all duration-500 transform ':''"
            class="focus:outline-none m-2 text-gray-800 font-bold py-2 px-4 w-1/2 flex justify-center"
            style="border-radius: 0.75rem;">
            <Iconphone />
            <span class="ml-1 text-xs">{{ $t("rechargeSuccess.phone") }}</span>
        </button>
    </div>
    
    <form @submit.prevent="signUp" data-vv-scope="form-1" class="mt-2 w-64 sm:w-11/12" v-show="!telephone">
        <label class="text-base" for="email">Email</label>
        <br />
        <div
            class="bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row items-center"
            :class="{ ' border-tex-2': submitted && errors.has('form-1.email') }">
            
            <span class="p-3">
                <Iconmail />
            </span>
            <input  class="leading-tight w-full focus:outline-none focus:bg-white" id="email" name="email"
                type="email"
                v-validate="'required|email'"
                v-model="userEmail"/>
        </div>
        <div v-show="submitted && errors.has('form-1.email')" class="flex items-center mt-2">
            <IconError />
            <span  class="ml-1 text-tex-2">{{ $t("sign.errorMail") }}</span>
        </div>
        <div v-show="conflictUser" class="flex items-center mt-2">
            <IconError />
            <span  class="ml-1">{{ $t("sign.existUser") }}</span>
        </div>
        <button type="submit"
            class="focus:outline-none text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded mt-4 flex items-center justify-center">
            <Iconperson v-show="!signupRequest"/>
            <span v-show="!signupRequest" class="ml-2">{{ $t("rechargeSuccess.receiveBtn") }}</span>
            <LoaderCss class="ml-16" v-show="signupRequest"/>
        </button>
    </form>

    <form @submit.prevent="signUpPhone" data-vv-scope="form-2" class="mt-2 w-64 sm:w-11/12" v-show="telephone">
        <label class="text-base" for="phone">{{ $t("rechargeSuccess.phone") }}</label>
        <br />
        <div >
            <vue-tel-input 
                inputClasses=""
                placeholder=''
                name="phone" 
                inputId="phone"
                :enabledCountryCode="true"
                :validCharactersOnly="true"
                @country-changed="countryChanged"
                wrapperClasses = "h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                v-model="userPhone">
            </vue-tel-input>
            <div v-show="phoneError" class="flex items-center mt-1">
                <IconError/><span class="ml-1 text-tex-2">{{ $t("sign.errorNumber") }}</span>
            </div>
        </div>
        <div v-show="conflictUserPhone" class="flex items-center mt-2">
            <IconError />
            <span  class="ml-1">{{ $t("sign.existUser") }}</span>
        </div>
        <button type="submit"
            class="focus:outline-none text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded mt-4 flex items-center justify-center">
            <Iconperson v-show="!signupRequest"/>
            <span v-show="!signupRequest" class="ml-2">{{ $t("rechargeSuccess.receiveBtn") }}</span>
            <LoaderCss class="ml-16" v-show="signupRequest"/>
        </button>
    </form>
</div>
</template>

<script>
import axios from 'axios'
import Iconrbill from "../../assets/svg/icons/receiveBill.svg?inline";
import IconBigbill from "../../assets/svg/icons/receiveBillBig.svg?inline";
import Iconperson from "../../assets/svg/icons/person.svg?inline";
import Iconmail from "../../assets/svg/icons/mail.svg?inline";
import Iconsmail from "../../assets/svg/icons/smallmail.svg?inline";
import {VueTelInput} from 'vue-tel-input'
import Iconphone from "../../assets/svg/icons/smallphone.svg?inline";
import LoaderCss from '@/components/LoaderCss';
import Iconsign from '../../assets/svg/icons/sign.svg?inline';
import IconError from "../../assets/svg/icons/errorIcon.svg?inline";
export default {
    props: {
        asFullPage: {
            type: Boolean,
            default: false
        }
    },
  components: {
    IconBigbill,
    Iconrbill,
    Iconperson,
    Iconmail,
    Iconsmail,
    Iconphone,
    VueTelInput,
    LoaderCss,
    Iconsign,
    IconError
  },
  data() {
    return {
        submitted: false,
        submittedPhone: false,
        telephone: false,
        signupRequest: false,
        userEmail: '',
        userPhone: '',
        conflictUser: false,
        conflictUserPhone: false,
        phoneError: false,
        countryCode: null,
    };
  },
  watch: {
    userEmail(){
        if(this.userEmail) this.conflictUser = false
        this.submitted= false
    },
    userPhone(){
        this.phoneError = false
        if(this.userPhone) this.conflictUserPhone = false
    }
},
  methods: {
    countryChanged(country) {
        this.countryCode = country.dialCode
    },
    signUp(){
        this.submitted = true;
            this.$validator.validateAll('form-1').then(valid => {
            if (valid) {
                this.signupRequest = true

                this.$store.commit('mutUser',{email: this.userEmail,countryCode: '',  phone: ''})
                
                const user = this.$store.getters.getUser;
                axios.post('/users/sendverifycode',user)
                .then(res => {
                    console.log(res)
                    this.$router.push('signframe')
                })
                .catch(err => {
                    this.signupRequest = false
                    console.log(err.response.data)
                    const statusCode = err.response.data.statusCode
                    if(statusCode == 409) this.conflictUser = true
                })
                    
            }
            });
        
    },
    
    signUpPhone(){
        this.userPhone = this.userPhone.replace(/\s+/g, '');
            if(this.userPhone.length >= 8) {
                this.submittedPhone = true;
                this.$validator.validateAll('form-2').then(valid => {
                if (valid) {
                    this.signupRequest = true

                    this.$store.commit('mutUser',{email: '',countryCode: this.countryCode, phone: this.userPhone})
                    
                    const user = this.$store.getters.getUser;
                    axios.post('/users/sendverifycode',user)
                    .then(res => {
                        console.log(res)
                        this.$router.push('signframe')
                    })
                    .catch(err => {
                        this.signupRequest = false
                        console.log(err.response.data)
                        const statusCode = err.response.data.statusCode
                        if(statusCode == 409) this.conflictUserPhone = true
                    })
                        
                }
            });
        
            }
            else {
                this.phoneError = true
            }
        
        
    },
    billReceiveMethod() {
      this.telephone = !this.telephone;
    },
    
  },
  mounted() {
      this.signupRequest = false
  },
};
</script>

<style scoped>
.vue-tel-input {
    border-radius: 3px;
    display: flex;
    border: 1px solid #C6D6EC;
    text-align: left;
}

.vue-tel-input:hover{
    border-color: #A5BCE0;
}
.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: #4661B9;
}

.vue-tel-input:focus{
    outline: none !important;
    border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within  {
    outline-style: none;
    border-top-left-radius: 0.125rem/* 2px */;
    border-bottom-left-radius: 0.125rem/* 2px */;
}
</style>